import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LandingPage from './LandingPage';
import UploadPage from './UploadPage';
import CustomInvitePage from './CustomInvite';
import ExternalRedirect from './Redirect';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/upload" element={<UploadPage />} />
        <Route path="/custominvite" element={<CustomInvitePage />} />
        <Route path="/invite" element={<ExternalRedirect to="https://discord.gg/tradebase" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
